.popup_reservation .react-datepicker__day--highlighted {
  border-radius: unset !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  border-bottom: solid 2px gray;
}

.popup_reservation .react-datepicker__day--keyboard-selected {
  border-radius: 11px;
}
.react-datepicker__day--highlighted-custom-1 {
  position: relative;
  color: #333 !important;
}
.react-datepicker__day--highlighted-custom-1::before {
  content: '';
  position: absolute;
  width: 13px;
  height: 2px;
  border-radius: 1px;
  background-color: #3772d0;
  bottom: 3px;
  left: 50%;
  transform: translateX(-50%);
}
.react-datepicker__day--highlighted-custom-1.react-datepicker__day--selected {
  color: #fff !important;
}
