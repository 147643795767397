@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  body {
    @apply font-HiraKakuProN;
  }
  .scrollbar-gray::-webkit-scrollbar {
    width: 5px;
  }

  .scrollbar-gray::-webkit-scrollbar-track {
    background-color: #eee;
    border-radius: 10px;
  }

  .scrollbar-gray::-webkit-scrollbar-thumb {
    background-color: #ababab;
    border-radius: 10px;
  }
}

.react-pdf__Document {
  position: relative;
}

.react-pdf__Page {
  position: absolute !important;
}

.btn-bg-gray,
.bg-gray {
  background-color: #333333;
}

.stop-stretching {
  width: 1%;
  white-space: nowrap;
}

.bg-main {
  background-color: #ececec;
}

.btn-primary {
  background-color: #002ea8;
}

.draggable-modal-overlay .draggable-modal {
  background: #fff;
  border: 1px solid #666;
  width: 400px;
  box-shadow: 0 0 50px rgb(0 0 0 / 50%);
  position: relative;
  z-index: 10;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-clamp-1 {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
img {
  object-fit: cover;
}

/*  */
.wrapper-frame-zoom div:last-of-type div:first-of-type {
  right: -45px !important;
  top: 41px !important;
  border-radius: 0 !important;
}

.wrapper-frame-zoom svg {
  filter: invert(22%) sepia(99%) saturate(3521%) hue-rotate(214deg)
    brightness(96%) contrast(81%);
}
.wrapper-frame-zoom .close-zoom-image {
  top: 0;
  right: -45px;
  border-bottom: 1px solid rgb(204, 204, 204);
}

/* Sidebar */
.sidebar-item {
  padding: 8px;
  display: block;
}

.sidebar-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block: 1px;
}
.sidebar-title .toggle-btn {
  cursor: pointer;
  transition: transform 0.3s;
}
.sidebar-item.open {
  padding: 0;
}
.sidebar-item.open > .sidebar-title .toggle-btn {
  transform: rotate(180deg);
}
.sidebar-content {
  height: 0;
  overflow: hidden;
  padding-left: 35px;
}
.sidebar-content .active + .active {
  background-color: unset;
}
.sidebar-content .sidebar-content {
  padding-left: 20px;
}
.sidebar-item.open > .sidebar-content {
  height: auto;
}
.sidebar-item.open > .sidebar-title {
  padding: 8px;
}
.sidebar-link {
  margin-block: 1px;
}
.sidebar-item:hover,
.sidebar-link:hover,
.sidebar-item.open .sidebar-title:hover,
.sidebar-item.open .sidebar-content .sidebar-item:hover {
  background-color: #394150;
  border-radius: 6px;
}
.sidebar-item.open:hover,
.sidebar-item.open .sidebar-item.open:hover {
  background-color: unset;
}
.active {
  background-color: #394150;
  border-radius: 6px;
}
.sidebar-collapsed .link-title {
  display: none;
}
.sidebar-item.open .sidebar-content span {
  font-size: 16px;
  line-height: 19px;
}
.sidebar-item.open .sidebar-content .sidebar-content span {
  font-size: 14px;
  line-height: 17px;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fc .fc-col-header-cell-cushion {
  font-weight: 300;
  color: #333333;
}
.fc-header-toolbar .fc-toolbar-chunk:first-child {
  margin-left: -8px;
}
.fc-header-toolbar .fc-toolbar-chunk:first-child .fc-toolbar-title {
  margin-left: 12px !important;
  margin-right: 12px !important;
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  height: 100px;
}
.break-word {
  word-break: break-word;
}
