.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.checked {
  background-color: rgb(31, 31, 179);
  appearance: none;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  border-radius: 5px;
}