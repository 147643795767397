body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'HiraKakuProNBold';
  src: url('../public/fonts/HiraKakuProN-W6-AlphaNum.woff2') format('woff2'),
    url('../public/fonts/HiraKakuProN-W6-AlphaNum.woff') format('woff'),
    url('../public/fonts/HiraKakuProN-W6-AlphaNum.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HiraKakuProN';
  src: url('../public/fonts/HiraKakuProN-W3-AlphaNum.woff2') format('woff2'),
    url('../public/fonts/HiraKakuProN-W3-AlphaNum.woff') format('woff'),
    url('../public/fonts/HiraKakuProN-W3-AlphaNum.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

input[type="color"] {
	-webkit-appearance: none;
	border: none;
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
	border: none;
}
