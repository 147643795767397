.fc-daygrid-day-top {
  justify-content: center; 
  font-size: 14px;
  color: #333333;
}
.fc-daygrid-more-link {
  background: #f8f8f8;
  padding: 0.125rem 0.75rem;
  display: inline-block;
  border: 1px solid #eceff4;
  border-radius: 99px;
  width: 100%;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
}
.fc-header-toolbar .fc-toolbar-chunk:first-child { 
  display: flex;
  align-items: center;
}
.fc-header-toolbar .fc-toolbar-chunk:first-child>div {
  display: flex;
  align-items: center;
}
.fc-header-toolbar .fc-toolbar-chunk:first-child .fc-toolbar-title {
  font-size: 18px;
  margin-left: 3px;
  margin-right: 3px;
  color: #333333;
  font-weight: 600;
}
.fc-header-toolbar .fc-toolbar-chunk:first-child .fc-today-button {
  background-color: #262626;
  border-radius: 99px;
  height: 30px;
  font-size: 16px;
  padding: 3px 15px;
}
.fc-header-toolbar .fc-toolbar-chunk:first-child .fc-button.fc-prev-button,
.fc-header-toolbar .fc-toolbar-chunk:first-child .fc-button.fc-next-button {
  padding: 0px;
}
.fc .fc-toolbar.fc-header-toolbar{
  margin-bottom: 12px !important;
}